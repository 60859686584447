import React from 'react'; 

import { Frame } from './components/index';

import { Route, Switch, Redirect} from 'react-router-dom';
import { adminRouter } from './routes';

// app 级别降低， 仅仅是一个页面而已！！
class App extends React.Component {

  state = {
    title:"知识库列表",
    show:true,
    tabelList:[
      {
        id:"a1",
        name:"语文"
      },
      {
        id:"a2",
        name:"数学"
      }
    ],
    article:"<div>danger div </div>"
  }

  setInpuList(newValue){
    this.setState({
      tabelList: this.state.tabelList.concat([{
        id:newValue,
        name:newValue
      }])
    })
  }

  render(){
    return (
      <Frame>
        <Switch>
          
          {
            adminRouter.map(route =>{
              return (
                <Route
                  exact
                  key={route.pathname} 
                  path={route.pathname} 
                  render={ (routerProps) =>{
                    return <route.component {...routerProps} />
                  }}
                />
              )
              
            })
          }

          <Redirect to={adminRouter[0].pathname} from='/admin' exact />
          <Redirect to="/404" />
        </Switch>
      </Frame>
    )
  }
}

export default App;
