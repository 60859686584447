import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as userActionCreators from '../../actions/user-actionCreators';


import * as THREE from 'three';

import { EffectComposer } from '../public/three/EffectComposer.js';
import { RenderPass } from '../public/three/RenderPass.js';
import { ShaderPass } from '../public/three/ShaderPass.js'; 
import { FilmPass } from '../public/three/FilmPass.js';
import { FocusShader } from '../public/three/FocusShader.js';
import { OBJLoader } from '../public/three/OBJLoader.js';

class Person extends Component {

  constructor(props){
    super();

  }
  
  componentDidMount(){

			var camera, scene, renderer, mesh;

			var parent, meshes = [], clonemeshes = [];

			var composer, effectFocus;

			var clock = new THREE.Clock();

			let width = document.getElementById('person-container').clientWidth;
			let height = document.getElementById('person-container').clientHeight;
		

			init();
			animate();

			function init() {

				var container = document.querySelector( '#person-container' );

				camera = new THREE.PerspectiveCamera( 70, width / height, 1, 50000 );
				camera.up.x = 0;
				camera.up.y = 1;
				camera.up.z = 0;

				camera.position.set(0, 1000, 1000);
				camera.lookAt(new THREE.Vector3(0, 0, 0));

				scene = new THREE.Scene();
				scene.background = new THREE.Color( 0x000104 );
				scene.fog = new THREE.FogExp2( 0x000104, 0.0000675 );

				//camera.lookAt( scene.position );

				var loader = new OBJLoader();

				loader.load( 'threejs/obj/male02.obj', function ( object ) {

					var positions = combineBuffer( object, 'position' );
					
					createMesh( positions, scene, 4.05, - 500, - 350, 600, 0xff7744 );

				} );
				
				renderer = new THREE.WebGLRenderer();
				//renderer.setPixelRatio( window.devicePixelRatio );
				renderer.setSize( width, height );
				renderer.autoClear = false;
				container.appendChild( renderer.domElement );

				parent = new THREE.Object3D();
				scene.add( parent );


				var renderModel = new RenderPass( scene, camera );
				var effectFilm = new FilmPass( 0.5, 0.5, 1448, false );

				effectFocus = new ShaderPass( FocusShader );

				effectFocus.uniforms[ "screenWidth" ].value = width * window.devicePixelRatio;
				effectFocus.uniforms[ "screenHeight" ].value = height * window.devicePixelRatio;

				composer = new EffectComposer( renderer );

 				composer.addPass( renderModel );
 				composer.addPass( effectFilm );
 				composer.addPass( effectFocus );

			}

			function combineBuffer( model, bufferName ) {

				let count = 0;

				model.traverse( function ( child ) {

					if ( child.isMesh ) {

						var buffer = child.geometry.attributes[ bufferName ];

						count += buffer.array.length;

					}

				} );

				var combined = new Float32Array( count );

				let offset = 0;

				model.traverse( function ( child ) {

					if ( child.isMesh ) {

						var buffer = child.geometry.attributes[ bufferName ];

						combined.set( buffer.array, offset );
						offset += buffer.array.length;

					}

				} );

				return new THREE.BufferAttribute( combined, 3 );

			}

			function createMesh( positions, scene, scale, x, y, z, color ) {

				var geometry = new THREE.BufferGeometry();
				geometry.addAttribute( 'position', positions.clone() );
				geometry.addAttribute( 'initialPosition', positions.clone() );

				geometry.attributes.position.setDynamic( true );

				var clones = [
					[ 0, 0, 0 ]
				];


				mesh = new THREE.Points( geometry, new THREE.PointsMaterial( { size: 30, color: color } ) );
				mesh.scale.x = mesh.scale.y = mesh.scale.z = scale;

				mesh.position.x = x + clones[ 0 ][ 0 ];
				mesh.position.y = y + clones[ 0 ][ 1 ];
				mesh.position.z = z + clones[ 0 ][ 2 ];

				parent.add( mesh );

				clonemeshes.push( { mesh: mesh, speed: 0.5 + Math.random() } );
 

				meshes.push( {
					mesh: mesh, verticesDown: 0, verticesUp: 0, direction: 0, speed: 15, delay: Math.floor( 200 + 200 * Math.random() ),
					start: Math.floor( 100 + 200 * Math.random() ),
				} );

			}

			function animate() {

				requestAnimationFrame( animate );
				render();

			}

			function render() {

				var delta = 10 * clock.getDelta();

				delta = delta < 2 ? delta : 2;

				parent.rotation.y += - 0.02 * delta;

				for ( var j = 0; j < clonemeshes.length; j ++ ) {

					var cm = clonemeshes[ j ];
					cm.mesh.rotation.y += - 0.1 * delta * cm.speed;

				}

				for ( var j = 0; j < meshes.length; j ++ ) {

					var data = meshes[ j ];
					var positions = data.mesh.geometry.attributes.position;
					var initialPositions = data.mesh.geometry.attributes.initialPosition;

					var count = positions.count;

					if ( data.start > 0 ) {
						data.start -= 1;
					} else {
// 						if ( data.direction === 0 ) {
// 							data.direction = - 1; //  这个会导致 人像 崩塌
// 						}
					}

					for ( var i = 0; i < count; i ++ ) {

						var px = positions.getX( i );
						var py = positions.getY( i );
						var pz = positions.getZ( i );

						// falling down
						if ( data.direction < 0 ) {

							if ( py > 0 ) {

								positions.setXYZ(
									i,
									px + 1.5 * ( 0.50 - Math.random() ) * data.speed * delta,
									py + 3.0 * ( 0.25 - Math.random() ) * data.speed * delta,
									pz + 1.5 * ( 0.50 - Math.random() ) * data.speed * delta
								);

							} else {

								data.verticesDown += 1;

							}

						}

						// rising up
						if ( data.direction > 0 ) {

							var ix = initialPositions.getX( i );
							var iy = initialPositions.getY( i );
							var iz = initialPositions.getZ( i );

							var dx = Math.abs( px - ix );
							var dy = Math.abs( py - iy );
							var dz = Math.abs( pz - iz );

							var d = dx + dy + dx;

							if ( d > 1 ) {

								positions.setXYZ(
									i,
									px - ( px - ix ) / dx * data.speed * delta * ( 0.85 - Math.random() ),
									py - ( py - iy ) / dy * data.speed * delta * ( 1 + Math.random() ),
									pz - ( pz - iz ) / dz * data.speed * delta * ( 0.85 - Math.random() )
								);

							} else {

								data.verticesUp += 1;

							}

						}

					}

					// all vertices down
					if ( data.verticesDown >= count ) {

						if ( data.delay <= 0 ) {

							data.direction = 1;
							data.speed = 5;
							data.verticesDown = 0;
							data.delay = 320;

						} else {

							data.delay -= 1;

						}

					}

					// all vertices up
					if ( data.verticesUp >= count ) {

						if ( data.delay <= 0 ) {

							data.direction = - 1;
							data.speed = 15;
							data.verticesUp = 0;
							data.delay = 120;

						} else {

							data.delay -= 1;

						}

					}

					positions.needsUpdate = true;

				}

				composer.render( 0.01 );

			}
  }

  render (){
    
    return (
      <div style={{width:'500px', height:'100%',float: 'right'}} id="person-container">
         
      </div>
    )
  }
}

const mapStateToProps = (state)=>{
  return {
    user:state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addUser: (user) => {
      dispatch(userActionCreators.addUser(user))
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Person)