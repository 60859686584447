import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as userActionCreators from '../../actions/user-actionCreators';

import * as THREE from 'three';

class Rect extends Component {

  constructor(props) {
    super();

  }

  componentDidMount() {
    
    let width = document.getElementById('rect-container').clientWidth;
    let height = document.getElementById('rect-container').clientHeight;

    let renderer;
    function initThree() {
      renderer = new THREE.WebGLRenderer({
        antialias : true
      });
      renderer.setSize(width, height);
      document.getElementById('rect-container').appendChild(renderer.domElement);
      renderer.setClearColor(0x000, 1.0);
    }

    var camera;
    function initCamera() {
      camera = new THREE.PerspectiveCamera(75, width / height, 1, 10000);
      camera.up.x = 0;
      camera.up.y = 1;
      camera.up.z = 0;

      camera.position.set(0, 1000, 800);
      camera.lookAt(new THREE.Vector3(0, 0, 0));
      

    }

    var scene;
    function initScene() {
      scene = new THREE.Scene();
    }

    var light;
    function initLight() {
      light = new THREE.AmbientLight(0xFFF);
      light.position.set(100, 100, 200);
      scene.add(light);
    }

    var group;
    function initObject() {

      var geometry = new THREE.BoxGeometry(60, 60, 60);

      for (var i = 0; i < geometry.faces.length; i += 2) {
        //var hex = Math.random() * 0xffffff;
        var hex = 0x1890ff;
        geometry.faces[i].color.setHex(hex);
        geometry.faces[i + 1].color.setHex(hex);
      }

      var material = new THREE.MeshBasicMaterial({ vertexColors: THREE.FaceColors });
      let mesh = new THREE.Mesh(geometry, material);
      mesh.translateX(0);
      mesh.translateY(50);
      mesh.translateZ(0);
      
      let mesh2 = new THREE.Mesh(geometry, material);
      mesh2.translateX(150);
      mesh2.translateY(50);
      mesh2.translateZ(0);
      
      let mesh3 = new THREE.Mesh(geometry, material);
      mesh3.translateX(-150);
      mesh3.translateY(50);
      mesh3.translateZ(100);

      let mesh4 = new THREE.Mesh(geometry, material);
      mesh4.translateX(0);
      mesh4.translateY(50);
      mesh4.translateZ(200);

      group = new THREE.Group();
      group.add(mesh);
      group.add(mesh2);
      group.add(mesh3);
      group.add(mesh4);

      scene.add(group);
    }

    function initGrid() {
      var helper = new THREE.GridHelper(1000, 50);
      helper.setColors(0x0000ff, 0xFF0000);
      scene.add(helper);
    }

    function threeStart() {
      initScene();
      initCamera();
      initThree();
      initLight();
     
      initObject();
      initGrid();
      animation();


    }

    function animation() {

      //group.rotation.x +=0.01;
      camera.updateProjectionMatrix();
      group.rotation.y += 0.01;
      renderer.render(scene, camera);
      requestAnimationFrame(animation);

    }

     
    threeStart();
    

  }

  render() {

    return (
      <div style={{ width: '500px', height: '100%', float: 'right' }} id="rect-container">

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addUser: (user) => {
      dispatch(userActionCreators.addUser(user))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rect)