//为了防止actiontype重复和写错， 在这里面统一定义 action的type；

export const CART_INCREMENT = "cart-increment";

export const CART_DECREMENT = "cart-decrement"; 

//为了防止actiontype重复和写错， 在这里面统一定义 action的type；

export const ADD_LOG = "ADD_LOG";

//为了防止actiontype重复和写错， 在这里面统一定义 action的type；

export const ADD_USER = "ADD_USER";
export const CLEAR_USER = "CLEAR_USER";