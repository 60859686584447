import { ADD_USER, CLEAR_USER } from '../actions/actionTypes';

const userState = {
  name:"",
  id:"",
  password:"",
  readme:false
};

export default (state = userState, action) => {
  if(action.type === ADD_USER) {
    return action.user;
  }
  else if(action.type === CLEAR_USER) {
    if(action.user.readme){ //有 记住我，不清空login
      return state;
    }
    return {
      name:"",
      id:"",
      password:""
    };
  }
  return state;
}