import React from 'react';

class Loading extends React.Component {

  render(){
    return (
      <div>
        请稍候...
      </div>
    )
  }
}

export default Loading;