import React from 'react';
import { connect } from 'react-redux';
import * as userActionCreators from '../../actions/user-actionCreators';
import { adminRouter } from '../../routes';
import { Layout, Menu, Badge, Breadcrumb, Dropdown, Icon  } from 'antd';
import { withRouter } from 'react-router-dom';

import './frame.less';
import 'antd/dist/antd.less';

const { Header, Content } = Layout;

class Frame extends React.Component {

  onMenuClick = ({item, key, keyPath, dimEvent}) =>{
    this.props.history.push({
      pathname:key
    })
  }

  onLogoutClick = ()=>{
    localStorage.removeItem("sys-user");
    this.props.clearUser(this.props.user);
    this.props.history.push({
      pathname:'/login'
    })
  }

  render(){

    const menu = (
      <Menu style={{width:'130px'}}>
        <Menu.Item key="11" path="/" >-</Menu.Item>
        <Menu.Item key="22" path="/" >-</Menu.Item>
        <Menu.Divider />
        <Menu.Item key="login" path="/login" onClick={this.onLogoutClick}>登出</Menu.Item>
      </Menu>
    );
    let user = JSON.parse( localStorage.getItem('sys-user') );
    return (
      <>
      <Layout style={{ height: '100%' }}>
        <Header className="header">
          <div className="logo"> {/* <img src={logo} width="42px" height="42px" /> */}{/* <span className="logo-span">分析系统</span> */}</div>
          <Menu
            mode="horizontal"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['图表分析']}
            style={{ lineHeight: '64px' }}
            selectedKeys={[this.props.location.pathname]}
          >
            {/* 右上角用户组 todo */}
            <Menu.Item 
              key={"a1"}
              style={{ margin: '0 -10px 0 16px' , float : 'right'}}
            >
              <Dropdown
                overlay={menu}
                trigger={['click']}
              >
                <a className="ant-dropdown-link" href="javascript:void(0);" style={{ color: '#09a5ee'}} >
                  {
                    user && user.name 
                    ?
                    user.name
                    :
                    this.onLogoutClick()
                  }
                  <Icon type="down" />
                </a>
              </Dropdown>
            </Menu.Item>

            {
              adminRouter.map((item)=>{
                if(item.level === 1){
                  return (
                    <Menu.Item 
                      key={item.pathname}
                      onClick={this.onMenuClick}
                      style={{ margin: '0 16px' , float : 'right'}}
                    >
                      {item.title === "图表分析" ? <Badge count={100} offset={[21,1]} overflowCount={9} showZero><span>{item.title} </span></Badge>: item.title}
                    </Menu.Item>
                  )
                }
                return ''
              })
            }

            
            
          </Menu>

          
        </Header>
        <Layout style={{ height: 'calc(100% - 70px)' }}>
          {/* <Sider width={200} style={{ background: '#fff' }}></Sider> */}
          <Layout style={{ padding: '0 24px 24px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
              {/* <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
              <Breadcrumb.Item>App</Breadcrumb.Item> */}
            </Breadcrumb>
            <Content
              style={{
                background: '#fff',
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              {this.props.children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user:state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearUser: (user) => {
      dispatch(userActionCreators.clearUser(user));
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Frame));