import {
  Home,
  Login,
  Notfound,
  JTbaobiaoItem,
  JTbaobiaoItemPage,
  DataSystem
} from '../views';

export const homeRouter = [
  {
    pathname:'/login',
    component: Login,
    title:'登录',
    isNav:true
  },
  {
    pathname:'/home',
    component: Home,
    title:'首页',
    isNav:true
  },
  {
    pathname:'/infoview',
    component: JTbaobiaoItem,
    title:"详情",
    isNav:true,
  },
  // 没有放大样式
  {
    pathname:'/infoviewpage',
    component: JTbaobiaoItemPage,
    title:"详情",
    isNav:true,
  },
  // 数据血缘
  {
    pathname:'/datasystem',
    component: DataSystem,
    title:"详情",
    isNav:true,
  },
  {
    pathname:'/404',
    component: Notfound
  }
]

export const adminRouter = [
  {
    pathname:'/infoviewpage',
    component: JTbaobiaoItemPage,
    title:"详情",
    isNav:true,
    level:1,
  },
]