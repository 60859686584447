/* 统一存放action  作用就是生成了 action！！*/
import { ADD_USER, CLEAR_USER } from './actionTypes';

// 其实是个函数 ， 返回 的是一个action
export const addUser = (user) => {

	return {  //下面也是一种方式，我们在这只返回一个action
		type: ADD_USER,
		user
	}
	/* return (dispatch)=>{   //可以在这返回一个函数，传入的是dispatch
		const action = {
			type: ADD_USER,
			user
		};

		dispatch(action);
	} */
}

export const clearUser = (user) => {
	return { type: CLEAR_USER, user }
}
